import CellBuilder from "components/cell-builder/CellBuilder";
import ElipsisMenu, { MenuItem } from "components/elipsis-menu/ElipsisMenu";

const expandedStatusColumn = [
  {
    selector: (row) => CellBuilder(row.commodity),
    grow: 2,
    style: { marginLeft: "46px" },
  },
  {
    selector: (row) => CellBuilder(row.shipping_cleared),
    grow: 2,
  },
  {
    selector: (row) => CellBuilder(row.under_customs),
    grow: 2,
  },
  {
    selector: (row) => CellBuilder(row.customs_cleared),
    grow: 2,
  },
  {
    selector: (row) => CellBuilder(row.tender),
    grow: 1,
  },
  {
    selector: (row) => CellBuilder(row.booked),
    grow: 1,
  },
  {
    selector: (row) => CellBuilder(row.inprogress),
    grow: 1,
  },
  {
    selector: (row) => CellBuilder(row.delivered),
    grow: 1,
  },
  // {
  //   name: "Actions",
  //   selector: () => (
  //     <div className="table-actions">
  //       <ElipsisMenu>
  //         <MenuItem title="Edit" />
  //         <MenuItem title="Delete" />
  //       </ElipsisMenu>
  //     </div>
  //   ),
  //   grow: 1,
  // },
];
export default expandedStatusColumn;
