import { getCookie } from "services/cookies";

export const hasPermission = (permissionName) => {
  if (permissionName === true) {
    return true;
  }
  const allPermissions = getCookie("UserPermissions") || [];
  const permission = allPermissions.find(
    (item) => item.name === permissionName
  );
  return permission?.enabled;
};
