import CellBuilder from "components/cell-builder/CellBuilder";
import AddDocumentModal from "components/document/AddDocumentModal";
import ElipsisMenu, { MenuItem } from "components/elipsis-menu/ElipsisMenu";
import { useEffect } from "react";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { getElements, postElement } from "services/apiEndpoints/Endpoints";
import dateFormatter from "utils/dateFormater";
import { GrDocumentMissing } from "react-icons/gr";

const OperatorDocs = ({ operatorId }) => {
  const [contactOpen, setContactOpen] = useState(false);
  const [contacts, setContacts] = useState([]);

  const getContacts = () => {
    getElements(`Authority/Operator/${operatorId}/Document`).then((res) => {
      setContacts(res.data?.data);
    });
  };

  const deleteDoc = (doc) => {
    const modifiedDocs = contacts.map((item) =>
      item.documentId === doc.documentId
        ? {
            documentId: doc.documentId,
            operatorId: doc.operatorId,
            documentType: doc.documentType,
          }
        : item
    );
    postElement(
      `/Authority/Operator/${operatorId}/Document`,
      modifiedDocs
    ).then((response) => {
      if (response.success) {
        getContacts();
      }
    });
  };

  const downloadDocument = (url) => {
    getElements(`/Document?documentUrl=${url}`, {
      responseType: "blob",
      timeout: 30000,
    }).then((response) => {
      console.log(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      let { type } = response.data;
      let fileType = type.substring(type.lastIndexOf("/") + 1);
      let fileName = url.substring(url.lastIndexOf("/") + 1);
      //let fullName = fileName + "." + `${customType ? customType : fileType}`;
      let fullName = fileName + "." + `${fileType}`;

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fullName);
      document.body.appendChild(link);
      link.click();
    });
  };
  useEffect(getContacts, []);
  return (
    <div>
      <div className="ema-card">
        <div className="ema-card-title d-flex align-items-center justify-content-between">
          <div>Documents</div>
        </div>

        <div className="summary-list">
          <DataTable
            data={contacts}
            columns={[
              {
                name: "Document",
                selector: (row) =>
                  CellBuilder(
                    <div>
                      {row.documentType}{" "}
                      {!row.documentUrl && (
                        <span style={{ color: "red" }}>
                          <GrDocumentMissing />
                        </span>
                      )}
                    </div>,
                    row.documentNumber
                  ),
                grow: 4,
              },
              {
                name: "Issued Date",
                selector: (row) =>
                  CellBuilder(
                    dateFormatter(row.documentIssuedUtc, "DD MMM YYYY")
                  ),
                grow: 2,
              },
              {
                name: "Expiration Date",
                selector: (row) =>
                  CellBuilder(
                    dateFormatter(row.documentExpirationUtc, "DD MMM YYYY")
                  ),
                grow: 2,
              },

              {
                name: "Actions",
                cell: (row) => {
                  return (
                    <div className="table-actions">
                      {row.documentUrl ? (
                        <i
                          className="icon-arrow-bg-down clickable"
                          onClick={() => downloadDocument(row.documentUrl)}
                        />
                      ) : (
                        <i
                          className="icon-arrow-bg-down"
                          style={{ color: "transparent" }}
                        />
                      )}
                      <i
                        className="icon-edit"
                        style={{ cursor: "pointer" }}
                        onClick={() => setContactOpen(row)}
                      />

                      <ElipsisMenu>
                        {row.documentUrl && (
                          <MenuItem
                            title="Delete Document"
                            onClick={() => {
                              deleteDoc(row);
                            }}
                          />
                        )}
                      </ElipsisMenu>
                    </div>
                  );
                },
                grow: 2,
              },
            ]}
          />
        </div>
        {contactOpen && (
          <AddDocumentModal
            closeModal={() => setContactOpen(false)}
            docObj={contactOpen}
            contacts={contacts}
            getContacts={getContacts}
            postingUrl={`/Authority/Operator/${operatorId}/Document`}
          />
        )}
      </div>
    </div>
  );
};

export default OperatorDocs;
