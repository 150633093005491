import "./OperationalActivity.sass";
import { useEffect, useState } from "react";
import StatusTable from "./status-table/StatusTable";
import EntryTable from "./entry-table/EntryTable";
import { useTranslation } from "react-i18next";

const OperationalActivity = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(1);
  const [unmountTabView, setUnmountTabView] = useState(1);

  const onTabChange = (tabNumber) => {
    if (tabNumber !== tab) {
      setTab(tabNumber);
      setUnmountTabView(true);
    }
  };

  useEffect(() => {
    if (unmountTabView) {
      setUnmountTabView(false);
    }
  }, [unmountTabView]);

  return (
    <div className="operational-activity">
      <div className="operational-sidebar">
        <div className="title-bar">
          <strong className="title-label" style={{ paddingRight: "4px" }}>
            {t("Operational Activity")}
          </strong>
        </div>
        <div>
          <section
            className={tab === 1 ? "selected" : ""}
            onClick={() => onTabChange(1)}
          >
            {t("Import Status")}
          </section>
          <section
            className={tab === 2 ? "selected" : ""}
            onClick={() => onTabChange(2)}
          >
            {t("Import Origin & Destination")}
          </section>
          <hr />
          <section
            className={tab === 3 ? "selected" : ""}
            onClick={() => onTabChange(3)}
          >
            {t("Export Status")}
          </section>
          <section
            className={tab === 4 ? "selected" : ""}
            onClick={() => onTabChange(4)}
          >
            {t("Export Origin & Destination")}
          </section>
          <hr />
          <section
            className={tab === 5 ? "selected" : ""}
            onClick={() => onTabChange(5)}
          >
            {t("Domestic Status")}
          </section>
          <section
            className={tab === 6 ? "selected" : ""}
            onClick={() => onTabChange(6)}
          >
            {t("Domestic Origin & Destination")}
          </section>
        </div>
      </div>
      {
        //This is to clear the page and prevent component memory persistence
        unmountTabView ? (
          <></>
        ) : tab % 2 ? (
          <StatusTable
            laneType={tab === 1 ? "Import" : tab === 3 ? "Export" : "Domestic"}
          />
        ) : (
          <EntryTable
            laneType={tab === 2 ? "Import" : tab === 4 ? "Export" : "Domestic"}
          />
        )
      }
    </div>
  );
};

export default OperationalActivity;
