const findError = (inputError, errors) => {
  if (errors !== undefined) {
    let error = errors.find(
      (responseError) =>
        responseError.key &&
        responseError.key.toLowerCase() === inputError.toLowerCase()
    );
    return error ? error.errorMessage : false;
  }
};

export default findError;
