import React from "react";
import "./TopMenuBar.sass";
import MainMenus from "./main-menus/MainMenus";
import logo from "assets/logo/logo.jpeg";
import Profile from "./profile/Profile";
import Language from "./language-menu/Language";

const TopMenuBar = () => {
  return (
    <div className="top-menu-bar">
      <div>
        <img
          src={logo}
          alt="Unified Transport & e-Logistics"
          className="app-logo"
        />

        <MainMenus />
      </div>
      <div className="d-flex align-items-center">
        <Language />
        <i className="mil-notification-o" style={{ fontSize: "20px" }} />
        <Profile />
      </div>
    </div>
  );
};

export default TopMenuBar;
