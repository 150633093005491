import "./AppContent.sass";
import { TopBarProvider } from "./top-menu-bar/context/TopBarContext";
import RoutesRenderer from "layout/routes/RoutesRenderer";
import TopMenuBar from "./top-menu-bar/TopMenuBar";
import TokenExpirationWarning from "services/tokenExpiration/TokenExpirationWarning";
import { ToastContainer } from "react-toastify";

const AppContent = () => (
  <div className="app-wrapper">
    <TokenExpirationWarning />
    <ToastContainer
      position="top-center"
      autoClose={3000}
      hideProgressBar={true}
      closeOnClick={true}
      pauseOnHover={false}
      draggable={true}
      progress={undefined}
    />

    <div className="app">
      <TopBarProvider>
        <div className="app-content">
          <TopMenuBar />
          <div className="view">
            <RoutesRenderer />
          </div>
        </div>
      </TopBarProvider>
    </div>
  </div>
);

export default AppContent;
