import React from "react";
import { useState } from "react";
//import { Nav, NavItem, NavLink } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import menuRoutes from "./menuRoutes";
import { useTranslation } from "react-i18next";

const MainMenus = () => {
  /* const currentPath = window.location.pathname;
  const [selectedNav, setSelectedNav] = useState(
    currentPath === "/" ? "/dashboard" : currentPath
  ); */
  const { t } = useTranslation();
  return (
    <div className="main-menus">
      {menuRoutes.map((item) => (
        <NavLink
          to={item.path}
          //onClick={() => setSelectedNav(item.title)}
          //className={selectedNav === item.path ? "selected" : ""}
        >
          {t(item.title)}
        </NavLink>
      ))}
    </div>
  );
};

export default MainMenus;
