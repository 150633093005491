import DataTable from "react-data-table-component";
import statusColumn from "./statusCulumn";
import expandedStatusColumn from "./ExpandedStatusTableColumn";
import { getElements } from "services/apiEndpoints/Endpoints";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const StatusTable = ({ laneType }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState([]);

  const Getstatus = () => {
    getElements(`reporting/commodity/status?lane_type=${laneType}`).then(
      (res) => {
        setExpanded(res.data);
      }
    );
  };
  useEffect(Getstatus, [laneType]);

  const ExpandedComponent = ({ data }) => {
    return (
      <div className="ema-table short-table">
        <DataTable
          data={data.commodities}
          columns={expandedStatusColumn}
          noTableHead
          noHeader
          customStyles={{
            rows: {
              style: {
                background: "#f2f2f2",
              },
            },
          }}
        />
      </div>
    );
  };

  return (
    <div className="operational-display">
      <div className="title-bar">
        <strong className="title-label">{t(`${laneType || ""} Status`)}</strong>
      </div>
      <div className="summary-list">
        <DataTable
          columns={statusColumn.map((c) => ({
            ...c,
            name: t(c.name),
          }))}
          data={expanded}
          expandableRows
          expandableRowsComponent={ExpandedComponent}
        />
      </div>
    </div>
  );
};

export default StatusTable;
