import EmaInput from "components/emaInput/EmaInput";
import React from "react";
import { useState, useEffect } from "react";

import "./advancedSearch.sass";
import useDebounce from "utils/useDebounce";

const AddressSearcher = ({ label, value, onSelect, showSearch }) => {
  const [inputValue, setInputValue] = useState("");
  const [realInputValue, setRealInputValue] = useState("");

  const [searchState, setSearch] = useState({
    result: [],
    resultShow: false,
  });

  const debounceSearch = useDebounce(realInputValue, 500);

  const getFilteredResult = (query) => {
    let data = showSearch.filter((item) => item.fullAddress.includes(query));
    setSearch({
      ...searchState,
      result: data,
      resultShow: true,
    });
  };

  const changed = (e) => {
    let value = e.target.value;
    setInputValue(value);
    setRealInputValue(value);
  };

  const clickAway = () => {
    setInputValue(value);
    setTimeout(() => {
      searchState.resultShow &&
        setSearch({ ...searchState, resultShow: false });
    }, 500);
  };

  useEffect(() => {
    debounceSearch && getFilteredResult(debounceSearch);
  }, [debounceSearch]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div className="advanced-search">
      <EmaInput
        label={label}
        type="search"
        onChange={changed}
        value={inputValue || ""}
        onBlur={clickAway}
      />
      {searchState.resultShow && (
        <div className="advance-search__result">
          {searchState.result.map((item, index) => (
            <div
              className="advance-search__item container "
              key={item.id}
              onClick={() => onSelect(item)}
            >
              <h5 className="advance-search__item-title row">
                {item.locationName || "N/A"}
              </h5>
              <span className=" row">{item.fullAddress}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AddressSearcher;
