import "./cell-builder.sass";

function CellBuilder(prim, sec, profilePic) {
  return (
    <div
      className={
        profilePic
          ? "cell-builder d-flex  align-items-center "
          : " cell-builder"
      }
    >
      {profilePic && (
        <img
          src={profilePic}
          alt="ProfilePic"
          className="rounded-circle img-fluid "
          style={{ height: 35, width: 35, marginRight: 10 }}
        />
      )}
      <div>
        {prim && <div className="prim-text ">{prim}</div>}
        {sec && <div className="sec-text">{sec}</div>}
      </div>
    </div>
  );
}

export default CellBuilder;
