const iconList = [
  "icon-user-o" /*
    content: "\e900";
  */,
  "icon-user-group-o" /*
    content: "\e901";
  */,
  "icon-user-group-f" /*
    content: "\e902";
  */,
  "icon-user-f" /*
    content: "\e903";
  */,
  "icon-truck-frontal" /*
    content: "\e904";
  */,
  "icon-truck-fast-o" /*
    content: "\e905";
  */,
  "icon-truck-fast-f" /*
    content: "\e906";
  */,
  "icon-setting-o" /*
    content: "\e907";
  */,
  "icon-setting-f" /*
    content: "\e908";
  */,
  "icon-search" /*
    content: "\e909";
  */,
  "icon-save-o" /*
    content: "\e90a";
  */,
  "icon-save-f" /*
    content: "\e90b";
  */,
  "icon-plus-square" /*
    content: "\e90c";
  */,
  "icon-plus-circle-o" /*
    content: "\e90d";
  */,
  "icon-plus-circle-f" /*
    content: "\e90e";
  */,
  "icon-plus" /*
    content: "\e90f";
  */,
  "icon-phone-o" /*
    content: "\e910";
  */,
  "icon-phone-f" /*
    content: "\e911";
  */,
  "icon-notification-o" /*
    content: "\e912";
  */,
  "icon-notification-f" /*
    content: "\e913";
  */,
  "icon-more-vertical" /*
    content: "\e914";
  */,
  "icon-more-horizontal" /*
    content: "\e915";
  */,
  "icon-money" /*
    content: "\e916";
  */,
  "icon-message-o" /*
    content: "\e917";
  */,
  "icon-message-f" /*
    content: "\e918";
  */,
  "icon-menu-v" /*
    content: "\e919";
  */,
  "icon-menu-fold" /*
    content: "\e91a";
  */,
  "icon-menu-duo" /*
    content: "\e91b";
  */,
  "icon-menu5" /*
    content: "\e91c";
  */,
  "icon-menu4" /*
    content: "\e91d";
  */,
  "icon-menu3" /*
    content: "\e91e";
  */,
  "icon-menu2" /*
    content: "\e91f";
  */,
  "icon-menu1" /*
    content: "\e920";
  */,
  "icon-menu" /*
    content: "\e921";
  */,
  "icon-market-f" /*
    content: "\e922";
  */,
  "icon-maintenance-o" /*
    content: "\e923";
  */,
  "icon-maintenance-f" /*
    content: "\e924";
  */,
  "icon-Logout" /*
    content: "\e925";
  */,
  "icon-lock-opened-o" /*
    content: "\e926";
  */,
  "icon-lock-opened-f" /*
    content: "\e927";
  */,
  "icon-lock-closed-o" /*
    content: "\e928";
  */,
  "icon-lock-closed-f" /*
    content: "\e929";
  */,
  "icon-location-o" /*
    content: "\e92a";
  */,
  "icon-location-f" /*
    content: "\e92b";
  */,
  "icon-info-o" /*
    content: "\e92c";
  */,
  "icon-info-f" /*
    content: "\e92d";
  */,
  "icon-home-o" /*
    content: "\e92e";
  */,
  "icon-home-f" /*
    content: "\e92f";
  */,
  "icon-hold-o" /*
    content: "\e930";
  */,
  "icon-fleet" /*
    content: "\e931";
  */,
  "icon-Filter" /*
    content: "\e932";
  */,
  "icon-email-o" /*
    content: "\e933";
  */,
  "icon-email-f" /*
    content: "\e934";
  */,
  "icon-email-at" /*
    content: "\e935";
  */,
  "icon-edit" /*
    content: "\e936";
  */,
  "icon-details" /*
    content: "\e937";
  */,
  "icon-detail-menu" /*
    content: "\e938";
  */,
  "icon-delete-o" /*
    content: "\e939";
  */,
  "icon-delete-f" /*
    content: "\e93a";
  */,
  "icon-close-sm-o" /*
    content: "\e93b";
  */,
  "icon-close-sm-f" /*
    content: "\e93c";
  */,
  "icon-close-sm" /*
    content: "\e93d";
  */,
  "icon-close-bg" /*
    content: "\e93e";
  */,
  "icon-clock-f" /*
    content: "\e93f";
  */,
  "icon-chevron-sm-up" /*
    content: "\e940";
  */,
  "icon-chevron-sm-right" /*
    content: "\e941";
  */,
  "icon-chevron-sm-left" /*
    content: "\e942";
  */,
  "icon-chevron-sm-down" /*
    content: "\e943";
  */,
  "icon-chevron-bg-up" /*
    content: "\e944";
  */,
  "icon-chevron-bg-right" /*
    content: "\e945";
  */,
  "icon-chevron-bg-left" /*
    content: "\e946";
  */,
  "icon-chevron-bg-down" /*
    content: "\e947";
  */,
  "icon-check-circle" /*
    content: "\e948";
  */,
  "icon-check-bold" /*
    content: "\e949";
  */,
  "icon-check" /*
    content: "\e94a";
  */,
  "icon-caution" /*
    content: "\e94b";
  */,
  "icon-arrow-sm-up" /*
    content: "\e94c";
  */,
  "icon-arrow-sm-right" /*
    content: "\e94d";
  */,
  "icon-arrow-sm-left" /*
    content: "\e94e";
  */,
  "icon-arrow-sm-down" /*
    content: "\e94f";
  */,
  "icon-arrow-diagonal-up" /*
    content: "\e950";
  */,
  "icon-arrow-diagonal-down" /*
    content: "\e951";
  */,
  "icon-arrow-corner-right" /*
    content: "\e952";
  */,
  "icon-arrow-corner-left" /*
    content: "\e953";
  */,
  "icon-arrow-bg-up" /*
    content: "\e954";
  */,
  "icon-arrow-bg-right" /*
    content: "\e955";
  */,
  "icon-arrow-bg-left" /*
    content: "\e956";
  */,
  "icon-arrow-bg-down" /*
    content: "\e957";
  */,
  "icon-accounting-dollar" /*
    content: "\e958";
  */,
];
export default iconList;
