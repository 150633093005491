import "react-toastify/dist/ReactToastify.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

import AppContent from "./layout/AppContent";
import AuthFailure from "services/authFailure/AuthFailure";
import { useState, useEffect } from "react";
import { checkJwtStatus, getJwt } from "services/Auth";

import "./App.sass";
import "assets/icon-fonts/icon-fonts.css";
import "assets/styles/icon-fonts/style.css";
import "react-toastify/dist/ReactToastify.min.css";

function AppWrapper() {
  const [appContent, setAppContent] = useState(<div className="loading"></div>);

  const getStatus = () => {
    let status = checkJwtStatus();
    if (status === "VALID") {
      appSetup();
    } else {
      getJwt(status).then((response) => {
        if (response.redirecting) {
          //Do nothing as redirect happens
        } else if (response.authFailed) {
          setAppContent(
            <AuthFailure message="Sorry, your login attempt was not successful." />
          );
        } else if (!response.authFailed) {
          appSetup();
        }
      });
    }
  };

  useEffect(getStatus, []);

  const appSetup = () => {
    setAppContent(<AppContent />);
  };

  return (
    <div>
      <>{appContent}</>
    </div>
  );
}

export default AppWrapper;
