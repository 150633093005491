import React, { useEffect, useState } from "react";
import VehicleForm from "./VehicleForm";
import EmaButton from "components/emaButton/EmaButton";
import { getElements, putElement } from "services/apiEndpoints/Endpoints";
import Loading from "components/loading/Loading";
import VehicleDocs from "./vehicle-details/VehicleDocs";
import { useTranslation } from "react-i18next";

function VehicleDetails({ setIsOpen, getVehicles, id }) {
  const { t } = useTranslation();
  const [vehicleData, setVehicleData] = useState({ plateState: "ET" });
  const [errors, setFormErrors] = useState([]);
  const [loading, setLoading] = useState(Loading);

  function handleChange(e) {
    setVehicleData({ ...vehicleData, [e.target.name]: e.target.value });
  }
  function handleSave() {
    putElement(`Authority/Vehicle/${id}`, vehicleData).then((res) => {
      if (res.success) {
        getVehicles();
        setIsOpen(false);
      } else if (res.vErrors) {
        setFormErrors(res.vErrors);
      }
    });
  }
  const getVehicle = () => {
    setLoading(loading);
    getElements(`Authority/Vehicle/${id}`).then((res) => {
      setVehicleData(res.data);
      setLoading(false);
    });
  };
  useEffect(getVehicle, []);
  return (
    <div>
      <div className="slider-header">
        <section className="slider-header-title">
          {vehicleData.unitNumber}
        </section>
        <section className="slider-header-buttons">
          <EmaButton title={t("Cancel")} onClick={() => setIsOpen(false)} />
          <EmaButton
            title={t("Save")}
            btnDark
            onClick={() => handleSave(vehicleData)}
          />
        </section>
      </div>
      <div className="slider-body">
        {loading ? (
          loading
        ) : (
          <>
            <VehicleForm
              vehicleData={vehicleData}
              handleChange={handleChange}
              errors={errors}
              carrierName={vehicleData?.carrierName || ""}
              id={id}
            />
            <VehicleDocs vehicleId={id} />
          </>
        )}
      </div>
    </div>
  );
}

export default VehicleDetails;
