import CellBuilder from "components/cell-builder/CellBuilder";
import ElipsisMenu, { MenuItem } from "components/elipsis-menu/ElipsisMenu";

const statusColumn = [
  {
    name: "Commodity",
    selector: (row) => CellBuilder(row.commodity_type),
    grow: 2,
  },
  {
    name: "Shipping Cleared",

    selector: (row) => CellBuilder(row.shipping_cleared_total),
    grow: 2,
  },

  {
    name: "Under Customs",
    selector: (row) => CellBuilder(row.under_customs_total),
    grow: 2,
  },
  {
    name: "Customs Cleared",
    selector: (row) => CellBuilder(row.customs_cleared_total),
    grow: 2,
  },
  {
    name: "Tender",
    selector: (row) => CellBuilder(row.tender_total),
    grow: 1,
  },
  {
    name: "Booked",
    selector: (row) => CellBuilder(row.booked_total),
    grow: 1,
  },

  {
    name: "Inprogress",
    selector: (row) => CellBuilder(row.inprogress_total),
    grow: 1,
  },
  {
    name: "Delivered",
    selector: (row) => CellBuilder(row.delivered_total),
    grow: 1,
  },
  /* {
    name: "Actions",
    selector: () => (
      <div className="table-actions">
        <ElipsisMenu>
          <MenuItem title="Edit" />
          <MenuItem title="Delete" />
        </ElipsisMenu>
      </div>
    ),
    grow: 1,
  }, */
];
export default statusColumn;
