import EmaButton from "components/emaButton/EmaButton";

function EditMarketPlace({ setIsOpen }) {
  return (
    <div>
      <div className="slider-header">
        <section className="slider-header-title"> Marketplace Details</section>
        <section className="slider-header-buttons">
          <EmaButton title="Cancel" onClick={() => setIsOpen(false)} />
          <EmaButton title="Save" btnDark onClick={() => {}} />
        </section>
      </div>
      <div className="slider-body"></div>
    </div>
  );
}

export default EditMarketPlace;
