import FilterComponent from "components/filterTypes/FilterComponent";
import React, { useEffect, useState } from "react";
import { getElements } from "services/apiEndpoints/Endpoints";
import AddressSearcher from "view/marketplace/AddressSearcher";

const ReportFilter = ({ request, setRequest, t }) => {
  const FilterMarkeplace = (state, setState, cancelFilter) => {
    const [showSearch, setShowSearch] = useState([]);
    const addressChange = (item, type) => {
      setState({
        ...state,
        [type]: item.locationId,
        [type + "Name"]: item.fullAddress,
      });
    };
    const getMarketLocation = () => {
      getElements(`/Marketplace/Location`).then((res) => {
        setShowSearch(res.data);
      });
    };
    useEffect(getMarketLocation, []);
    return (
      <>
        <div className="cancellable-filter">
          <AddressSearcher
            showSearch={showSearch}
            label={t("Origin")}
            value={state.originName}
            onSelect={(item) => addressChange(item, "origin")}
          />
          <i
            className="icon-close-sm-f cancel-icon"
            onClick={() => {
              cancelFilter(["origin", "originName"]);
            }}
          />
        </div>
        <div className="cancellable-filter">
          <AddressSearcher
            showSearch={showSearch}
            label={t("Destination")}
            value={state.destinationName}
            onSelect={(item) => addressChange(item, "destination")}
          />
          <i
            className="icon-close-sm-f cancel-icon"
            onClick={() => {
              cancelFilter(["destination", "destinationName"]);
            }}
          />
        </div>
      </>
    );
  };
  return (
    <div className="util-filter-cont">
      <FilterComponent
        request={request}
        setRequest={setRequest}
        title="Filters"
        renderOptions={FilterMarkeplace}
        selected={request.origin || request.destination}
      />
    </div>
  );
};

export default ReportFilter;
