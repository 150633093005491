import React, { useEffect, useState } from "react";
import TableTrailers from "./TableTrailers";
import { getElements } from "services/apiEndpoints/Endpoints";
import Loading from "components/loading/Loading";

import TrailerFilters from "./TrailersFilters";
import { useTranslation } from "react-i18next";

const Trailers = () => {
  const { t } = useTranslation();
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(Loading);
  const [request, setRequest] = useState({
    status: "",
    from_utc: "",
    to_utc: "",
    limit: 50,
    offset: 1,
  });

  const getVehicles = () => {
    setLoading(Loading);
    const queryStr = new URLSearchParams({
      ...request,
      status: request.status.replaceAll(" ", ""),
    }).toString();
    getElements("Authority/trailer?" + queryStr).then((res) => {
      setVehicles(res.data);
      setLoading(false);
    });
  };

  useEffect(getVehicles, [request]);

  return (
    <div>
      <div className="title-bar">
        <strong className="title-label">{t("Trailers")}</strong>
        <TrailerFilters request={request} setRequest={setRequest} />
      </div>

      {loading ? (
        loading
      ) : (
        <>
          <TableTrailers
            vehicles={vehicles}
            getVehicles={getVehicles}
            updateVehiclesNDashboard={() => {
              getVehicles();
            }}
            request={request}
            setRequest={setRequest}
          />
        </>
      )}
    </div>
  );
};

export default Trailers;
