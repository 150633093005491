import React from "react";
import iconList from "./iconNames";
import "./IconsDisplay.sass";

const IconsDisplay = () => {
  return (
    <div className="icon-display">
      <h4>
        prefix with <span>"icon-"</span> before the icon name.
      </h4>
      {iconList.sort().map((i) => (
        <div className="icon-display-card">
          <i className={i} />
          <p>{i.replace("icon-", "")}</p>
        </div>
      ))}
    </div>
  );
};

export default IconsDisplay;
