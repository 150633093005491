import EmaButton from "components/emaButton/EmaButton";
import React from "react";
import ActiveCard from "./dashboard-cards/ActiveCard";
import "./dashboard.sass";
import PieChartCard from "./dashboard-cards/PieChartCard";
import ImportExportCard from "./dashboard-cards/ImportExportCard";
import { chartData } from "./dashboard-cards/chartData";
import { getElements } from "services/apiEndpoints/Endpoints";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation();
  const [chartDashboard, setChartDashboard] = useState([]);
  const [exportCommodities, setExportCommodities] = useState([]);
  const [importCommodities, setImportCommodities] = useState([]);
  const [importExport, setImportExport] = useState({});
  const getDashboard = () => {
    getElements(`dashboard/main/actionable`).then((response) => {
      setChartDashboard(response.data);
    });
  };
  const getCommodities = () => {
    getElements(`dashboard/main/commodity`).then((response) => {
      //console.log(response.data);
      const commodities = response.data?.commodityDashboards || [];
      let exports = [];
      let imports = [];
      commodities.sort((a, b) => {
        return b.commodityVolume - a.commodityVolume;
      });
      commodities.forEach((a) => {
        if (a.isExport) exports.push(a);
        else imports.push(a);
      });

      setExportCommodities(exports);
      setImportCommodities(imports);
      setImportExport({
        totalExportVolume: response.data.totalExportVolume,
        totalImportVolume: response.data.totalImportVolume,
      });
    });
  };

  useEffect(() => {
    getDashboard();
    getCommodities();
  }, []);
  return (
    <div className="dashboard">
      <div className="title-bar">
        <strong className="title-label">{t("Dashboard")}</strong>
        <div>
          <EmaButton
            title={"4 " + t("Weeks")}
            icon="mil-chevron-sm-down"
            reversed
          />
        </div>
      </div>
      <div className="dashboard-cards">
        <div className="dash-cards-flex-cont flex-column">
          <div className="dash-cards-flex-cont">
            <ActiveCard response={chartDashboard} />
          </div>
          <div className="dash-cards-flex-cont pie-charts-cont">
            <PieChartCard data={importCommodities} type="import" />
            <PieChartCard data={exportCommodities} type="export" />
          </div>
        </div>
        <div
          className="dash-cards-flex-cont flex-column"
          style={{ width: "20%" }}
        >
          <ImportExportCard response={importExport} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
