import CookieService from "encrypted-cookie";

export const setCookie = (key, value, expires) => {
  CookieService.setCookie(key, JSON.stringify(value), expires);
  return;
};

//invalidates cookies by setting expiry to a past date
export const inValidateCookies = (clearAll) => {
  const allCookies = document.cookie;

  allCookies?.split(";").forEach((keyValuePair) => {
    const key = keyValuePair.split("=")[0]?.trim();

    //leave out refreshToken if clearAll is false
    if (clearAll || key !== "refreshToken") CookieService.eraseCookie(key);
  });
};

export const startSession = async (jwt) => {
  const {
    refreshToken,
    accessToken,
    expiresIn,
    expiresAt,
    refreshAt,
    userInfo,
  } = jwt;

  //expiration in expressed in days (because the library takes only days)
  let expiration = expiresIn / 3600 / 24;

  setCookie("userInfo", userInfo, expiration, true);
  setCookie(
    "cachedJwt",
    { accessToken, expiresIn, expiresAt, refreshAt },
    expiration,
    true
  );
  if (refreshToken) {
    setCookie("refreshToken", refreshToken, expiration);
  }
  console.log(jwt);

  return;
};

export const getCookie = (searchKey) => {
  let cookieValue = CookieService.getCookie(searchKey);
  if (cookieValue) {
    return JSON.parse(cookieValue);
  } else {
    return null;
  }
};
