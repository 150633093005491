import React, { useState } from "react";
import "./Language.sass";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import ethiopian from "assets/icons/ethiopian-flag.svg";
import american from "assets/icons/american-flag.svg";

const Language = () => {
  const [openToggle, setOpenToggle] = useState(false);
  const { i18n } = useTranslation();

  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    console.log(i18n.language);
  };
  return (
    <>
      <Dropdown
        isOpen={openToggle}
        toggle={() => setOpenToggle(!openToggle)}
        className="language"
      >
        <DropdownToggle
          onClick={() => setOpenToggle(!openToggle)}
          className="dropdown-toggler language-toggler"
        >
          <span className="language-menu-text">
            {i18n.language === "en" ? (
              <>
                <img src={american} /> EN
              </>
            ) : (
              <>
                <img src={ethiopian} /> አማ
              </>
            )}
          </span>
          {!openToggle ? (
            <i className="language-icon  icon-chevron-bg-down" />
          ) : (
            <i className="language-icon icon-chevron-bg-up" />
          )}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>
            <span
              className="language-menu-text"
              onClick={() => changeLang("en")}
            >
              <img src={american} />
              English
            </span>
          </DropdownItem>
          <DropdownItem>
            <span
              className="language-menu-text"
              onClick={() => changeLang("am")}
            >
              <img src={ethiopian} /> አማርኛ
            </span>
          </DropdownItem>
          <DropdownItem>
            <span
              className="language-menu-text"
              onClick={() => changeLang("om")}
            >
              <img src={ethiopian} /> Oromo
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default Language;
