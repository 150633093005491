import CellBuilder from "components/cell-builder/CellBuilder";
import ElipsisMenu, { MenuItem } from "components/elipsis-menu/ElipsisMenu";

export const entryColumns = [
  {
    name: "Commodity",
    selector: (row) => CellBuilder(row.commodity_type),
    grow: 2,
  },
  {
    name: "Tonnage",

    selector: (row) => CellBuilder(row.tonnage_total),
    grow: 1,
  },
  {
    name: "Truck",
    selector: (row) => CellBuilder(row.truck_total),
    grow: 1,
  },
  {
    name: "Rail",
    selector: (row) => CellBuilder(row.rail_total),
    grow: 1,
  },
  {
    name: "Air",
    selector: (row) => CellBuilder(row.air_total),
    grow: 1,
  },
  {
    name: "Origin",
    selector: () => CellBuilder("Multiple"),
    grow: 2,
  },
  {
    name: "Destination",
    selector: () => CellBuilder("Multiple"),
    grow: 2,
  },

  /* {
    name: "Actions",
    selector: () => (
      <div className="table-actions">
        <ElipsisMenu>
          <MenuItem title="Edit" />
          <MenuItem title="Delete" />
        </ElipsisMenu>
      </div>
    ),
    grow: 1,
  }, */
];
