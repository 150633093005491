import { useState } from "react";
import TrailerForm from "./TrailerForm";
import EmaButton from "components/emaButton/EmaButton";
import { postElement } from "services/apiEndpoints/Endpoints";

function NewTrailer({ setIsOpen, getVehicles, operator }) {
  const [newVehicle, setNewVehicle] = useState({
    plateState: "ET",
    operatorId: operator.operatorId,
  });
  const [errors, setFormErrors] = useState([]);

  const handleChange = (e) => {
    setNewVehicle({ ...newVehicle, [e.target.name]: e.target.value });
  };

  const handleClickSave = () => {
    postElement(`Authority/trailer`, newVehicle).then((res) => {
      if (res.success) {
        getVehicles();
        setIsOpen(false);
      } else if (res.vErrors) {
        setFormErrors(res.vErrors);
      }
    });
  };
  return (
    <div>
      <div className="slider-header">
        <section className="slider-header-title">New Trailers </section>
        <section className="slider-header-buttons">
          <EmaButton title="Cancel" onClick={() => setIsOpen(false)} />
          <EmaButton
            title="Save"
            btnDark
            onClick={() => handleClickSave(newVehicle)}
          />
        </section>
      </div>
      <div className="slider-body">
        <TrailerForm
          vehicleData={newVehicle}
          handleChange={handleChange}
          errors={errors}
          carrierName={operator.company}
          id={operator?.operatorId}
        />
      </div>
    </div>
  );
}

export default NewTrailer;
