import Countdown, { zeroPad } from "react-countdown";
import { logoutRedirect, checkJwtStatus, getJwt } from "services/Auth";
import { toast } from "react-toastify";
import "./TokenExpiration.sass";
import EmaButton from "components/emaButton/EmaButton";
import { useEffect } from "react";
import { appContext } from "services/AppContext";
import { useContext } from "react";
import AuthFailure from "services/authFailure/AuthFailure";
import { getCookie } from "services/cookies";

const TokenExpirationWarning = () => {
  const { tokenExpirationState, setTokenExpirationState } = useContext(
    appContext
  );

  const onTimerOver = () => {
    logoutRedirect();
  };

  const keepSignIn = () => {
    let status = checkJwtStatus();
    if (status === "VALID") {
      // DEFENSIVE: status should never be VALID. this is to prevent unresponsive button action incase it is.
      setTokenExpirationState({});
    } else {
      getJwt(status).then((response) => {
        if (response.redirecting) {
          //Do nothing as redirect happens
        } else if (response.authFailed) {
          return (
            <AuthFailure message="Sorry, your login attempt was not successful." />
          ); // take it out of the callback
        } else {
          setTokenExpirationState({});
        }
      });
    }
  };

  const warningComponent = (expiresAt) => (
    <div className="expiration-warning">
      <h4 className="expiration-warning-title">
        Your Session is about to expire!
      </h4>
      <div className="expiration-warning-body">
        <span>You will be logged out in</span>
        <Countdown
          date={expiresAt}
          onComplete={onTimerOver}
          renderer={({ seconds }) => (
            <span className="expiration-warning-timer">{zeroPad(seconds)}</span>
          )}
          zeroPadTime={2}
        />
        <span>Seconds</span>
      </div>
      <div className="expiration-warning-btns">
        <EmaButton onClick={keepSignIn} title="Keep Me Logged In" />
        <EmaButton onClick={logoutRedirect} title="Log Me Out" />
      </div>
    </div>
  );

  function showWarning() {
    toast.dismiss();
    const expiresAt = getCookie("cachedJwt")?.expiresAt;
    let warningTime = new Date(expiresAt) - new Date() - 59 * 1000;

    const warningTimeout = setTimeout(
      () =>
        toast(warningComponent(expiresAt), {
          autoClose: false,
          closeButton: false,
          closeOnClick: false,
          //onClose: doSomething,
        }),
      warningTime
    );
    return () => clearInterval(warningTimeout);
  }

  useEffect(showWarning, [tokenExpirationState]);

  return <></>;
};

export default TokenExpirationWarning;
