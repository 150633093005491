import React, { useEffect, useState } from "react";
import MarketplaceTable from "./MarketplaceTable";
import { getElements } from "services/apiEndpoints/Endpoints";
import Loading from "components/loading/Loading";
import MarketplaceFilter from "./MarketplaceFilter";
import { useTranslation } from "react-i18next";

const Marketplace = () => {
  const [marketplace, setMarketplace] = useState([]);
  const [loading, setLoading] = useState(Loading);

  const [request, setRequest] = useState({
    tender_type: "",
    trailer_type: "",
    available_from: "",
    available_to: "",
    origin: "",
    destination: "",
    is_quote: "",
    limit: 50,
    offset: 1,
  });

  const getMarketplace = () => {
    setLoading(Loading);
    const querystr = new URLSearchParams({
      ...request,
      tender_type: request.tender_type.replaceAll(" ", ""),
      trailer_type: request.trailer_type.replaceAll(" ", ""),
    }).toString();
    getElements("Marketplace?" + querystr).then((res) => {
      setMarketplace(res.data);
      setLoading(false);
    });
  };

  useEffect(getMarketplace, [request]);
  const { t } = useTranslation();

  return (
    <div>
      <div className="title-bar">
        <strong className="title-label">{t("Marketplace")}</strong>
        <div className="ml-5">
          <MarketplaceFilter request={request} setRequest={setRequest} t={t} />
        </div>
      </div>
      {loading ? (
        loading
      ) : (
        <MarketplaceTable
          marketplace={marketplace}
          request={request}
          setRequest={setRequest}
        />
      )}
    </div>
  );
};

export default Marketplace;
