import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { entryColumns } from "./entryColumns";
import expandedEntryColumn from "./ExpandedEntryColumn";
import { getElements } from "services/apiEndpoints/Endpoints";
import ReportFilter from "./ReportFilter";
import { useTranslation } from "react-i18next";
const EntryTable = ({ laneType }) => {
  const { t } = useTranslation();
  const [entryExpanded, setEntryExpanded] = useState([]);

  const [request, setRequest] = useState({
    origin: "",
    destination: "",
  });

  const Getentry = () => {
    const querystr = new URLSearchParams({
      origin: request.origin,
      destination: request.destination,
      lane_type: laneType,
    }).toString();

    getElements(`/reporting/commodity/volume?${querystr}`).then((res) => {
      setEntryExpanded(res.data);
    });
  };

  useEffect(Getentry, [laneType, request.destination, request.origin]);

  const ExpandedComponent = ({ data }) => (
    <div className="ema-table short-table">
      <DataTable
        data={data.commodities}
        columns={expandedEntryColumn}
        noTableHead
        noHeader
        customStyles={{
          rows: {
            style: {
              background: "#f2f2f2",
            },
          },
        }}
      />
    </div>
  );

  return (
    <div className="operational-display">
      <div className="title-bar">
        <strong className="title-label">
          {t(`${laneType || ""} Origin & Destination`)}
        </strong>
        <div>
          <ReportFilter request={request} setRequest={setRequest} t={t} />
        </div>
      </div>
      <div className="summary-list">
        <DataTable
          //columns={entryColumns}
          columns={entryColumns.map((c) => ({
            ...c,
            name: t(c.name),
          }))}
          data={entryExpanded}
          expandableRows
          expandableRowsComponent={ExpandedComponent}
        />
      </div>
    </div>
  );
};

export default EntryTable;
