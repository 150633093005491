import React from "react";
import { Route, Switch } from "react-router";
import ProtectedRoute from "services/ProtectedRoute";
import routes from "./routes";
//import { getCookie } from "utils/cookies";
const NotFoundPage = React.lazy(() => import("../NotFoundPage"));
import FragmentSupportingSwitch from "../FragmentSupportingSwitch";
import { hasPermission } from "utils/hasPermission";

const RoutesRenderer = () => {
  //const { subscription_plan } = getCookie("userInfo") || {};

  return (
    <Switch>
      <React.Suspense fallback="">
        <FragmentSupportingSwitch>
          {routes
            .filter((route) => {
              const permision = Object.keys(route).includes("hasPermission")
                ? route.hasPermission(hasPermission) //check if permission is enabled
                : true; // if hasPermission property is missing just return true
              //const showFor = (route.showFor || []).includes(subscription_plan);
              const showFor = route.showFor; // allow all for now
              return permision && showFor; //both need to be true to return the route
            })
            .map((r, i) => (
              <ProtectedRoute
                key={i}
                exact
                path={r.path}
                title={r.title}
                component={r.component}
              />
            ))}
          <Route component={NotFoundPage} />
        </FragmentSupportingSwitch>
      </React.Suspense>
    </Switch>
  );
};

export default RoutesRenderer;
