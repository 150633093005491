import { useContext } from "react";
import { Route } from "react-router-dom";
import { appContext } from "./AppContext";
//import { checkJwtStatus, getJwt } from "./Auth";
//import AuthFailure from "./authFailure/AuthFailure";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { setTokenExpirationState } = useContext(appContext);

  const refreshComponent = () => {
    //IMPORTANT: A rerender action is required (e.g setState) to run routeRenderer again which
    //provides component for the Route else nothing will be rendered for the Route. But for now setTokenExpirationState does that
    setTokenExpirationState({});
  };

  const routeRenderer = (props) => {
    //set the title of the pages
    if (rest?.title) {
      document.title = `${rest.title} | UniTeL`;
    } else {
      document.title = "UniTel";
    }

    //pass directly to showing the component FOR NOW
    return <Component {...props} />;

    //check status
    /* let status = checkJwtStatus();
    if (status === "VALID") {
      return <Component {...props} />;
    } else {
      // POINT OF FAILURE: status should never be MISSING OR EXPIRED. this is a QA info for when that occurs
      if (status === "MISSING" || status === "EXPIRED") {
        //pointOfFailureLogger("authentication status is MISSING OR EXPIRED");
      }
      getJwt(status).then((response) => {
        if (response.redirecting) {
          //Do nothing as redirect happens
        } else if (response.authFailed) {
          return (
            <AuthFailure message="Sorry, your login attempt was not successful" />
          ); // take it out of the callback
        } else {
          refreshComponent();
        }
      });
    } */
  };

  return <Route {...rest} render={(props) => routeRenderer(props)} />;
};

export default ProtectedRoute;
