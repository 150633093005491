import { useState, createContext } from "react";
import { useMediaQuery } from "react-responsive";

const appContext = createContext();

const AppContext = (props) => {
  const [enums, setEnums] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [undoModal, setUndoModal] = useState(false);
  const [factoryId, setFactoryId] = useState(0);
  const [carrierState, setCarrierState] = useState({}); //INFO:to be deleted
  const [tokenExpirationState, setTokenExpirationState] = useState({});
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const enumObject = (a) => {
    const obj = {};
    a.map(
      (b) =>
        (obj[b.type] = b.keys.map((k) => {
          return { keyName: k.keyName, keyValue: k.keyValue };
        }))
    );
    setEnums(obj);
  };

  const getEnums = (x) => {
    enumObject(x);
  };

  return (
    <div>
      <appContext.Provider
        value={{
          getEnums,
          enums,
          isMobile,
          formErrors,
          setFormErrors,
          undoModal,
          setUndoModal,
          factoryId,
          setFactoryId,
          setCarrierState,
          carrierState,
          tokenExpirationState,
          setTokenExpirationState,
        }}
      >
        {props.children}
      </appContext.Provider>
    </div>
  );
};

export { AppContext, appContext };
