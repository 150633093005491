import React from "react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

const PieChartCard = ({ data, type }) => {
  const ImportColorCodes = [
    "#13405F",
    "#1A5781",
    "#216EA3",
    "#2885C5",
    "#429BD9",
    "#68AFE0",
    "#8FC4E8",
    "#B5D8F0",
    "#B5D8F0",
    "#B5D8F0",
    "#B5D8F0",
    "#B5D8F0",
    "#B5D8F0",
    "#B5D8F0",
    "#B5D8F0",
    "#B5D8F0",
  ];
  const ExportColorCodes = [
    "#064E3B",
    "#065F46",
    "#059669",
    "#10B981",
    "#34D399",
    "#6EE7B7",
    "#A7F3D0",
    "#D1FAE5",
    "#D1FAE5",
    "#D1FAE5",
    "#D1FAE5",
    "#D1FAE5",
    "#D1FAE5",
    "#D1FAE5",
  ];

  const colorCodes = type === "import" ? ImportColorCodes : ExportColorCodes;

  const { t } = useTranslation();
  const doughnutData = {
    labels: data.map((d) => d.commodityName),
    datasets: [
      {
        label: "Revenue",
        data: data.map((d) => d.commodityVolume),
        backgroundColor: colorCodes,
        //data: ["36", "25", "24", "8", "7"],
        /*  backgroundColor: [
          data.colorCodes[0],
          data.colorCodes[2],
          data.colorCodes[5],
          data.colorCodes[6],
          data.colorCodes[7],
        ], */
        borderWidth: 0.4,
        width: "20px",
      },
    ],
  };
  const doughnutOptions = {
    responsive: false,
    //maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: 0,
  };

  return (
    <div className=" dashboard-card pie-chart-card">
      <section className="pie-chart-card_title">
        <label>{t("Commodity")}</label>
        <strong>{t(type === "import" ? "Import" : "Export")}</strong>
      </section>
      <div className="pie-chart-card_body mt-5">
        <section className="pie-chart-circle">
          <Doughnut
            data={doughnutData}
            options={doughnutOptions}
            height="180px"
            width="180px"
          />
        </section>
        <ul className="pie-chart-circle_items">
          {data.map((d, i) => (
            <li>
              <div>
                <span style={{ background: colorCodes[i] }}></span>
                <strong>{d.commodityName}</strong>
              </div>
              <label className="m-0 ml-3">
                {(d.commodityVolume * 100).toFixed(2)}%
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PieChartCard;
