import DateTimeInput from "components/datetime-input/DateTimeInput";
import FilterComponent from "components/filterTypes/FilterComponent";
import EmaInput from "components/emaInput/EmaInput";
import joinStrings from "utils/joinStrings";

const TrailerFilters = ({ request, setRequest }) => {
  const FilterWrapper = (state, setState, cancelFilter) => {
    const onChange = (e) => {
      const { value, name } = e.target;
      setState({ ...state, [name]: value });
    };
    return (
      <>
        <div className="cancellable-filter">
          <EmaInput
            type="select"
            value={state.status}
            name="status"
            label="Status"
            onChange={onChange}
            options={["Authorized", "Revoked", "Decommissioned"]}
          />
          <i
            className="icon-close-sm-f cancel-icon"
            onClick={() => cancelFilter("status")}
          />
        </div>
        <div className="cancellable-filter">
          <DateTimeInput
            type="date"
            label="From"
            value={state.from_utc}
            name="from_utc"
            onChange={onChange}
          />
          <i
            className="icon-close-sm-f cancel-icon"
            onClick={() => cancelFilter("from_utc")}
          />
        </div>
        <div className="cancellable-filter">
          <DateTimeInput
            type="date"
            label="To"
            value={state.to_utc}
            name="to_utc"
            onChange={onChange}
          />
          <i
            className="icon-close-sm-f cancel-icon"
            onClick={() => cancelFilter("to_utc")}
          />
        </div>
      </>
    );
  };
  return (
    <div className="util-filter-cont">
      <FilterComponent
        request={request}
        setRequest={setRequest}
        title="Filters"
        renderOptions={FilterWrapper}
        selected={request.status || request.from_utc || request.to_utc}
        getFilteredValues={() =>
          joinStrings(
            [request.status, request.authority_type, request.tin],
            ", "
          )
        }
        checkValuesExist={() =>
          request.status || request.authority_type || request.tin ? true : false
        }
        cancleFiclter={() =>
          setRequest({
            ...request,
            status: "",
            authority_type: "",
            tin: "",
          })
        }
      />
    </div>
  );
};

export default TrailerFilters;
