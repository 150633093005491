import React, { useEffect, useState } from "react";
import TableVehicle from "./TableVehicle";
import { getElements } from "services/apiEndpoints/Endpoints";
import Loading from "components/loading/Loading";

import VehicleFilters from "./VehicleFilters";
import VehicleDashboard from "./vehicle-dashboard/VehicleDashboard";
import { useTranslation } from "react-i18next";

const Vehicles = () => {
  const { t } = useTranslation();
  const [vehicles, setVehicles] = useState([]);
  const [vehicleDashboard, setVehicleDashboard] = useState([]);
  const [loading, setLoading] = useState(Loading);
  const [request, setRequest] = useState({
    status: "",
    from_utc: "",
    to_utc: "",
    limit: 50,
    offset: 1,
  });

  const getVehicles = () => {
    setLoading(Loading);
    const queryStr = new URLSearchParams({
      ...request,
      status: request.status.replaceAll(" ", ""),
    }).toString();
    getElements("Authority/Vehicle?" + queryStr).then((res) => {
      setVehicles(res.data);
      setLoading(false);
    });
  };
  const getVehicleDashboard = () => {
    getElements(`/dashboard/vehicle/actionable`).then((res) => {
      setVehicleDashboard(res.data);
    });
  };

  useEffect(getVehicles, [request]);
  useEffect(getVehicleDashboard, []);

  return (
    <div>
      <div className="title-bar">
        <strong className="title-label">{t("Vehicles")}</strong>
        <VehicleFilters request={request} setRequest={setRequest} />
      </div>

      {loading ? (
        loading
      ) : (
        <>
          <div>
            <VehicleDashboard response={vehicleDashboard} />
          </div>
          <TableVehicle
            vehicles={vehicles}
            getVehicles={getVehicles}
            updateVehiclesNDashboard={() => {
              getVehicleDashboard();
              getVehicles();
            }}
            request={request}
            setRequest={setRequest}
          />
        </>
      )}
    </div>
  );
};

export default Vehicles;
