import DateTimeInput from "components/datetime-input/DateTimeInput";
import FilterComponent from "components/filterTypes/FilterComponent";
import EmaInput from "components/emaInput/EmaInput";
import React, { useEffect, useState } from "react";
import AddressSearcher from "./AddressSearcher";
import { getElements } from "services/apiEndpoints/Endpoints";

const MarketplaceFilter = ({ request, setRequest, t }) => {
  const FilterMarkeplace = (state, setState, cancelFilter) => {
    const onChange = (e) => {
      const { value, name } = e.target;
      setState({ ...state, [name]: value });
    };
    const [showSearch, setShowSearch] = useState([]);
    const addressChange = (item, type) => {
      setState({
        ...state,
        [type]: item.locationId,
        [type + "Name"]: item.fullAddress,
      });
    };
    const getMarketLocation = () => {
      getElements(`/Marketplace/Location`).then((res) => {
        setShowSearch(res.data);
      });
    };
    useEffect(getMarketLocation, []);
    return (
      <>
        <div className="cancellable-filter">
          <EmaInput
            type="select"
            name="tender_type"
            label={t("Tender Type")}
            value={state.tender_type}
            onChange={onChange}
            options={["Direct Tender", "Private Tender", "Public Tender"]}
          />
          <i
            className="icon-close-sm-f cancel-icon"
            onClick={() => cancelFilter("tender_type")}
          />
        </div>
        <div className="cancellable-filter">
          <EmaInput
            type="select"
            name="trailer_type"
            label={t("Trailer Type")}
            value={state.trailer_type}
            onChange={onChange}
            options={[
              "Single Truck",
              "Truck Trailer",
              "High Bed Trailer",
              "Low Bed Trailer",
              " Reefer",
              " Single Tanker",
              "Tanker Trailer",
              "High Bed Tanker",
              "Unknown",
            ]}
          />
          <i
            className="icon-close-sm-f cancel-icon"
            onClick={() => cancelFilter("trailer_type")}
          />
        </div>

        <div className="cancellable-filter">
          <DateTimeInput
            type="date"
            label={t("Available From")}
            value={state.available_from}
            name="available_from"
            onChange={onChange}
          />
          <i
            className="icon-close-sm-f cancel-icon"
            onClick={() => cancelFilter("available_from")}
          />
        </div>
        <div className="cancellable-filter">
          <DateTimeInput
            type="date"
            label={t("Available To")}
            value={state.available_to}
            name="available_to"
            onChange={onChange}
          />
          <i
            className="icon-close-sm-f cancel-icon"
            onClick={() => cancelFilter("available_to")}
          />
        </div>

        <div className="cancellable-filter">
          <AddressSearcher
            showSearch={showSearch}
            label={t("Origin")}
            value={state.originName}
            onSelect={(item) => addressChange(item, "origin")}
          />
          <i
            className="icon-close-sm-f cancel-icon"
            onClick={() => {
              cancelFilter(["origin", "originName"]);
            }}
          />
        </div>
        <div className="cancellable-filter">
          <AddressSearcher
            showSearch={showSearch}
            label={t("Destination")}
            value={state.destinationName}
            onSelect={(item) => addressChange(item, "destination")}
          />
          <i
            className="icon-close-sm-f cancel-icon"
            onClick={() => {
              cancelFilter(["destination", "destinationName"]);
            }}
          />
        </div>
        <div className="cancellable-filter">
          <EmaInput
            type="select"
            label={t("Is Quote")}
            value={state.is_quote}
            name="is_quote"
            onChange={onChange}
            options={["true", "false"]}
          />
          <i
            className="icon-close-sm-f cancel-icon"
            onClick={() => cancelFilter("is_quote")}
          />
        </div>
      </>
    );
  };
  return (
    <div className="util-filter-cont">
      <FilterComponent
        request={request}
        setRequest={setRequest}
        title="Filters"
        renderOptions={FilterMarkeplace}
        selected={
          request.tender_type ||
          request.trailer_type ||
          request.available_from ||
          request.available_to ||
          request.origin ||
          request.destination ||
          request.is_quote
        }
      />
    </div>
  );
};

export default MarketplaceFilter;
