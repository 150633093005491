import { useTranslation } from "react-i18next";
import "./VehicleDashboard.sass";
function VehicleDashboard({ response }) {
  const { t } = useTranslation();
  const data = [
    {
      label: t("Active"),
      value: response.active,
    },
    {
      label: t("On Assignment"),
      value: response.onAssignment,
    },
    {
      label: t("Available"),
      value: response.available,
    },
    {
      label: t("Off Duty"),
      value: response.offDuty,
    },
    {
      label: t("Decommissioned"),
      value: response.decommissioned,
    },
  ];
  return (
    <>
      <div className="vehicle-dashboard">
        {data.map((vehicle, index) => (
          <section key={index} className="vehicle-dashboard-card">
            <label className="vehicle-dashboard-label">{vehicle.label}</label>
            <div className="vehicle-dashboard-value">{vehicle.value || 0}</div>
          </section>
        ))}
      </div>
    </>
  );
}

export default VehicleDashboard;
