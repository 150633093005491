import ReactDOM from "react-dom";
import AppWrapper from "./App";
import { AppContext } from "services/AppContext";
import { BrowserRouter as Router } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";

import "i18n";
import { Suspense } from "react";

ReactDOM.render(
  <Suspense fallback={<div>Loading</div>}>
    <AppContext>
      <Router>
        <AppWrapper />
      </Router>
    </AppContext>
  </Suspense>,
  document.getElementById("root")
);
