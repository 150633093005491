import DateTimePicker from "react-datetime-picker";
import dateFormatter from "utils/dateFormater";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import "./date-time-input.sass";
import dayjs from "dayjs";

const DateTimeInput = ({
  value,
  onChange,
  label,
  required,
  error,
  type,
  disabled,
  name,
  maxDate = new Date("9999-12-31"),
  minDate = new Date("1900-01-01"),
}) => {
  let format = "dd/MM/yyyy, HH:mm";
  type === "date"
    ? (format = "dd/MM/yyyy")
    : type === "time"
    ? (format = "HH:mm")
    : (format = "dd/MM/yyyy, HH:mm");
  let dayJsFormat = "YYYY-MM-DD, HH:mm";
  type === "date"
    ? (dayJsFormat = "YYYY-MM-DD")
    : type === "time"
    ? (dayJsFormat = "HH:mm")
    : (dayJsFormat = "YYYY-MM-DD, HH:mm");
  return (
    <div className="date-time-input">
      <label>{label}</label>
      {required && <span className="required-red">*</span>}
      <DateTimePicker
        format={format}
        value={value ? new Date(dateFormatter(value)) : ""}
        onChange={(dateValue) => {
          onChange({
            target: {
              value: dateValue ? dayjs(dateValue).format(dayJsFormat) : "",
              name: name,
            },
          });
        }}
        maxDate={type === "time" ? null : maxDate}
        minDate={type === "time" ? null : minDate}
        dayPlaceholder="dd"
        monthPlaceholder="mm"
        yearPlaceholder="yyyy"
        disableClock
        disableCalendar={type === "time"}
        disabled={disabled}
      />
      <small
        style={{
          color: "red",
        }}
      >
        {error}
      </small>
    </div>
  );
};

export default DateTimeInput;
