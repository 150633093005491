import "react-sliding-pane/dist/react-sliding-pane.css";
import SlidingPane from "react-sliding-pane";
import "./SliderPanel.sass";

const SliderPanel = ({ children, isOpen, setIsOpen, from = "right" }) => {
  return (
    <div>
      <SlidingPane
        className="slider-panel-wrapper"
        width="650px"
        isOpen={isOpen}
        from={from}
        hideHeader={true}
        onRequestClose={() => {
          setIsOpen(false);
        }}
        shouldCloseOnEsc={false}
      >
        {children}
      </SlidingPane>
    </div>
  );
};

export default SliderPanel;
