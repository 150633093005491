import React from "react";
import { useTranslation } from "react-i18next";
import { IoMdTrendingDown, IoMdTrendingUp } from "react-icons/io";

const ActiveCard = ({ response }) => {
  const { t } = useTranslation();
  const setUpTrend = (amount) => {
    let a = amount ? amount * 100 : 0;
    return a + "%";
  };

  const cardsValues = [
    {
      label: t("Active Carriers"),
      value: response.carriersActive,
      change: response.trendCarriers,
      changePercentage: setUpTrend(response.trendCarriers),
      changeType: "positive",
    },
    {
      label: t("Active Freight Forwarders"),
      value: response.forwardersActive,
      change: response.trendForwarders,
      changePercentage: setUpTrend(response.trendForwarders),
      changeType: "negative",
    },
    {
      label: t("Active Vehicles"),
      value: response.vehiclesActive,
      change: response.trendVehicles,
      changePercentage: setUpTrend(response.trendVehicles),
      changeType: "positive",
    },
  ];
  return (
    <>
      {cardsValues.map((a) => (
        <div className="dashboard-card dash-active-card ">
          <div>
            <label className="dash-card-sec-titl">{a.label}</label>
            <section className="dash-card-value-big-text">
              {a.value || 0}
            </section>
          </div>
          <div
            className={
              "dash-card-trend " + (a.change <= 0 ? "negative" : "positive")
            }
          >
            {a.changePercentage}
            {a.change >= 0 ? <IoMdTrendingUp /> : <IoMdTrendingDown />}
          </div>
        </div>
      ))}
    </>
  );
};

export default ActiveCard;
